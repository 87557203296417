import { VersionedRouter } from '@fergdigitalcommerce/fergy-core-react-web/esmDist/components/versioned-router';
import { createBrowserHistory, type LocationListener } from 'history';
import { type FunctionComponent, useEffect } from 'react';
import { type RouteProps, type RouterProps } from 'react-router';
import { JUMB_TAB_ANCHOR_PREFIX } from '../../constants/common-component.constants';
import { scrollToElement } from '../../utils/dom/dom.utils';

export type BuildRouterProps = {
	exclude: RouteProps[]; // Route
} & Partial<RouterProps>;

const browserHistory = createBrowserHistory();

function makeLocationChangeHandler(): LocationListener {
	return (location) => {
		if (location.hash) {
			// An exception for jump tabs
			if (!location.hash.startsWith(`#${JUMB_TAB_ANCHOR_PREFIX}`)) {
				scrollToElement(location.hash);
			}
		} else {
			window.scrollTo(0, 0);
		}
	};
}

/**
 * The purpose of this component is to generate a router that is in charge of keeping track of the history
 * of the site. Mainly for analytical purposes.
 */
export const BuildRouter: FunctionComponent<BuildRouterProps> = ({ children, history = browserHistory, exclude }) => {
	// History cannot change per react-router, so make this a one-time effect.
	useEffect(() => {
		return history.listen(makeLocationChangeHandler());
	}, [history]);

	return (
		<VersionedRouter history={history} exclude={exclude}>
			{children}
		</VersionedRouter>
	);
};
